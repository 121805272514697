.mostZoomedOutPro {
    position: absolute;
    border-radius: 5px;
}   

.mostZoomedOutProInner {
    padding: 5px;
}

.title {
    color: #000000;
    font-size: 12px;
}