.mostZoomedInPro {
    position: absolute;
    border-radius: 3px;
}   


.mostZoomedInProInner {
    display: grid;
    grid-template-columns: 1fr 15px;
    grid-template-rows: 100%;
    padding: 3px;
}   


.projectDetails {
    grid-row: 1/2;
    grid-column: 1/2;
}

.projectDetails p {
    margin: 0.5px 0px;
}


.projectDetails .title {
    font-size: 3px;
}

.projectDetails .description {
    font-size: 3px;
    max-height: 10px;
    overflow: hidden;
}



.projectManager {
    grid-row: 1/2;
    grid-column: 2/3;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.projectManager .image {
    width: 10px;
    height: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.projectManager .name {
    font-size: 3px;
}

