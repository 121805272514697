@import url(https://fonts.googleapis.com/css2?family=Athiti&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div, p, span {
    font-family: var(--primary-font-family);
    font-weight: 600;
    color: rgba(100,100,100,1);
}

.Timeline_timelineContainer__1n41B {
    width: 800px;
    box-shadow: 1px 1px 12px 0px rgb(150 150 150);
    border-radius: 20px;
    position: relative;
    top: 20px;
    left: 20px;
    bottom: 8px;
    height: -webkit-max-content;
    height: max-content;
}


.Timeline_timeline__3jlZk {
    display: grid;
    grid-template-rows: -webkit-max-content 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 100%;
}


.Timeline_timelineHeader__2rHv7 {
    grid-row: 1/2;
    grid-column: 1/2;
    border-bottom: 2px solid rgba(240,240,240,1);
}

.Timeline_timelineHeaderInner__1zVoM {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px 30px;
}

.Timeline_zoomHandler__3ld4F {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center; 
}


.Timeline_zoomHandler__3ld4F .Timeline_zoomText__dfYn4 {
    margin-right: 10px;
}

.Timeline_zoomHandler__3ld4F .Timeline_zoomInput__2qVkY {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}


/* years */



.Timeline_yearsDropdown__213LU input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0px;
    outline: 0px;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid rgba(234,240,240,1);
    font-family: var(--primary-font-family);
    font-weight: 600;
    color: rgba(100,100,100,1);
}


.Timeline_yearsDropdown__213LU  .Timeline_arrowIcon__3OzPv {
    position: absolute;
    top: 6px;
    right: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Timeline_yearsDropdown__213LU .Timeline_arrowIcon__3OzPv svg {
    fill: rgba(100,100,100,1);
}

.Timeline_yearsDropdown__213LU {
    position: relative;
    z-index: 1;
}

.Timeline_yearsDropdown__213LU .Timeline_listOfYears__2RRJY {
    position: absolute;
    width: 100%;
    box-shadow: 1px 1px 10px rgb(200 200 200);
    padding: 20px 0px;
    border-radius: 0px;
    top: 42px;
    z-index: 1002;
    background: white;
}


.Timeline_yearsDropdown__213LU .Timeline_listOfYears__2RRJY ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    overflow: auto;
    max-height: 300px;
}


.Timeline_yearsDropdown__213LU .Timeline_listOfYears__2RRJY ul li {
    background-color: rgba(255, 255, 255, 1);
    padding: 4px 45px 4px 10px;
    transition: background-color 300ms;
    cursor: pointer;
    color: var(--unnamed-color-156695);
    text-align: left;
    letter-spacing: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Timeline_yearsDropdown__213LU .Timeline_listOfYears__2RRJY ul li:hover {
    background-color: rgba(240,240,240,1);
}



/* --------------------- */

.Timeline_timelineBody__1YY3I {
    grid-row: 2/3;
    grid-column: 1/2;
    padding-bottom: 20px;
}



.Timeline_timelineBodyInner__3Irep {
    width: 800px;
    box-sizing: border-box;
}


.Timeline_projectionDataLayout__3ZEh0 {
    height: 360px;
    width: 800px;
    position: relative;
}

.Timeline_backgroundTimelineOverlay__3Vo8Q {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    
    display: -webkit-flex;
    
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.Timeline_timelineMonth__2TOW4 {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}


.Timeline_timelineMonth__2TOW4 .Timeline_dottedLine__3qI0f {
    width: 0px;
    height: 100%;
    border-right: 3px dashed #f0f0f0;
}



.Timeline_monthsList__1PHOI {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    height: auto;
    width: 100%;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
}

.Timeline_monthsListContainer__3McXq {
    border-top: 2px solid rgba(220,220,220,1);
    height: 70px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
}


.Timeline_lineDate__12FTC {
    height: calc(100%);
    border: 0px;
    width: 1px;
    position: absolute;
    left: calc((100% / 12) * 4);
    top: 0px;
    bottom: 0px;
    background-color: rgb(56, 97, 251);
}



.Timeline_tools__2bNgK {
    width: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    position: absolute;
    bottom: 100px;
    right: 30px;
    z-index: 1;
}

.Timeline_tools__2bNgK button {
    width: 30px;
    height: 30px;
    border: 0px;
    outline: 0px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: rgb(56, 97, 251);
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.Timeline_tools__2bNgK button:focus {
    border: 3px solid rgba(200, 200, 200, 0.5);
}

.Timeline_forwardMonth__1YR3N, .Timeline_backwardMonth__dbPgb {
    position: absolute;
    z-index: 1;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);
    width: 20px;
    height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}


.Timeline_forwardMonth__1YR3N {
    right: 5px;
}


.Timeline_backwardMonth__dbPgb {
    left: 5px;
}


.Timeline_forwardMonth__1YR3N svg, .Timeline_backwardMonth__dbPgb svg {
    fill: #FFFFFF;
    width: 18px;
    height: 18px;
}
.NormalPro_normalPro__3TPnf {
    position: absolute;
    border-radius: 5px;
}  

.NormalPro_normalProInner__93eIk {
    padding: 5px;
}   

.NormalPro_title__2TIWr {
    color: #000000;
    font-size: 12px;
}
.BitZoomedInPro_bitZoomedInPro__3tyOm {
    position: absolute;
    border-radius: 5px;
}   

.BitZoomedInPro_bitZoomedInProInner__1Ufnw {
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-template-rows: 100%;
    padding: 5px;
}


.BitZoomedInPro_projectDetails__2JsBv {
    grid-row: 1/2;
    grid-column: 1/2;
}

.BitZoomedInPro_projectDetails__2JsBv p {
    margin: 0px;
    font-size: 5px;
}


.BitZoomedInPro_projectDetails__2JsBv .BitZoomedInPro_title__3sOtx {
    font-size: 5px;
}


.BitZoomedInPro_projectDetails__2JsBv .BitZoomedInPro_description__BMvMR {
    font-size: 5px;
}



.BitZoomedInPro_projectManager__1Clpz {
    grid-row: 1/2;
    grid-column: 2/3;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.BitZoomedInPro_projectManager__1Clpz .BitZoomedInPro_image__3JEZH {
    width: 10px;
    height: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.BitZoomedInPro_projectManager__1Clpz .BitZoomedInPro_name__Ab34p {
    font-size: 5px;
}


.MostZoomedInPro_mostZoomedInPro__2M_j4 {
    position: absolute;
    border-radius: 3px;
}   


.MostZoomedInPro_mostZoomedInProInner__3ha3G {
    display: grid;
    grid-template-columns: 1fr 15px;
    grid-template-rows: 100%;
    padding: 3px;
}   


.MostZoomedInPro_projectDetails__1pHk2 {
    grid-row: 1/2;
    grid-column: 1/2;
}

.MostZoomedInPro_projectDetails__1pHk2 p {
    margin: 0.5px 0px;
}


.MostZoomedInPro_projectDetails__1pHk2 .MostZoomedInPro_title__1Zpzx {
    font-size: 3px;
}

.MostZoomedInPro_projectDetails__1pHk2 .MostZoomedInPro_description___GVlN {
    font-size: 3px;
    max-height: 10px;
    overflow: hidden;
}



.MostZoomedInPro_projectManager__39sYo {
    grid-row: 1/2;
    grid-column: 2/3;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
}

.MostZoomedInPro_projectManager__39sYo .MostZoomedInPro_image__XIqY3 {
    width: 10px;
    height: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.MostZoomedInPro_projectManager__39sYo .MostZoomedInPro_name__FwuoX {
    font-size: 3px;
}


.MostZoomedOutPro_mostZoomedOutPro__2gtc2 {
    position: absolute;
    border-radius: 5px;
}   

.MostZoomedOutPro_mostZoomedOutProInner__Ogilb {
    padding: 5px;
}

.MostZoomedOutPro_title__2szSe {
    color: #000000;
    font-size: 12px;
}
:root {
  --primary-font-family: 'Athiti', sans-serif;
}


.timelineContainer {
  width: 100%;
}

