div, p, span {
    font-family: var(--primary-font-family);
    font-weight: 600;
    color: rgba(100,100,100,1);
}

.timelineContainer {
    width: 800px;
    box-shadow: 1px 1px 12px 0px rgb(150 150 150);
    border-radius: 20px;
    position: relative;
    top: 20px;
    left: 20px;
    bottom: 8px;
    height: max-content;
}


.timeline {
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 100%;
}


.timelineHeader {
    grid-row: 1/2;
    grid-column: 1/2;
    border-bottom: 2px solid rgba(240,240,240,1);
}

.timelineHeaderInner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
}

.zoomHandler {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center; 
}


.zoomHandler .zoomText {
    margin-right: 10px;
}

.zoomHandler .zoomInput {
    display: flex;
    align-items: center;
}


/* years */



.yearsDropdown input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0px;
    outline: 0px;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid rgba(234,240,240,1);
    font-family: var(--primary-font-family);
    font-weight: 600;
    color: rgba(100,100,100,1);
}


.yearsDropdown  .arrowIcon {
    position: absolute;
    top: 6px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yearsDropdown .arrowIcon svg {
    fill: rgba(100,100,100,1);
}

.yearsDropdown {
    position: relative;
    z-index: 1;
}

.yearsDropdown .listOfYears {
    position: absolute;
    width: 100%;
    box-shadow: 1px 1px 10px rgb(200 200 200);
    padding: 20px 0px;
    border-radius: 0px;
    top: 42px;
    z-index: 1002;
    background: white;
}


.yearsDropdown .listOfYears ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    overflow: auto;
    max-height: 300px;
}


.yearsDropdown .listOfYears ul li {
    background-color: rgba(255, 255, 255, 1);
    padding: 4px 45px 4px 10px;
    transition: background-color 300ms;
    cursor: pointer;
    color: var(--unnamed-color-156695);
    text-align: left;
    letter-spacing: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.yearsDropdown .listOfYears ul li:hover {
    background-color: rgba(240,240,240,1);
}



/* --------------------- */

.timelineBody {
    grid-row: 2/3;
    grid-column: 1/2;
    padding-bottom: 20px;
}



.timelineBodyInner {
    width: 800px;
    box-sizing: border-box;
}


.projectionDataLayout {
    height: 360px;
    width: 800px;
    position: relative;
}

.backgroundTimelineOverlay {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    
    display: flex;
    flex-flow: row nowrap;
}

.timelineMonth {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.timelineMonth .dottedLine {
    width: 0px;
    height: 100%;
    border-right: 3px dashed #f0f0f0;
}



.monthsList {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-start;
    height: auto;
    width: 100%;
    transform-origin: 0% 0%;
}

.monthsListContainer {
    border-top: 2px solid rgba(220,220,220,1);
    height: 70px;
    display: flex;
    align-items: center;
    overflow: hidden;
}


.lineDate {
    height: calc(100%);
    border: 0px;
    width: 1px;
    position: absolute;
    left: calc((100% / 12) * 4);
    top: 0px;
    bottom: 0px;
    background-color: rgb(56, 97, 251);
}



.tools {
    width: 40px;
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    bottom: 100px;
    right: 30px;
    z-index: 1;
}

.tools button {
    width: 30px;
    height: 30px;
    border: 0px;
    outline: 0px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: rgb(56, 97, 251);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.tools button:focus {
    border: 3px solid rgba(200, 200, 200, 0.5);
}

.forwardMonth, .backwardMonth {
    position: absolute;
    z-index: 1;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);
    width: 20px;
    height: 20px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}


.forwardMonth {
    right: 5px;
}


.backwardMonth {
    left: 5px;
}


.forwardMonth svg, .backwardMonth svg {
    fill: #FFFFFF;
    width: 18px;
    height: 18px;
}