@import url('https://fonts.googleapis.com/css2?family=Athiti&display=swap');



:root {
  --primary-font-family: 'Athiti', sans-serif;
}


.timelineContainer {
  width: 100%;
}
